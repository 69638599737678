//=============================================================================
// SAYT - search as you type
//=============================================================================

import VOID from "./framework/framework";
import $ from "jquery";

const TEXT_ATTR = "data-sayt-text";

export function initSAYT(opts) {
  const $input = $(opts.input);
  const $content = $(opts.content);
  const $container = $(opts.container);
  const $submit = $(opts.submit);

  // used when clicking a link to:
  // - not hide the content (visual)
  // - not reload the content (functional - removed links sometimes aren't followed)
  let documentUnloading = false;

  $content.attr("tabindex", "0");

  function updateContent() {
    const focused = $input.is(":focus") || $.contains($content, document.activeElement);
    const enoughText = $input.val().trim().length >= 2;
    const visible = documentUnloading || focused && enoughText;

    $container.toggleClass("d-none", !visible);

    if (visible && !documentUnloading) {
      AJAX.send({
        scope: "pluginsearch",
        method: "replace",
        url: opts.url.replace("__NAME__", encodeURIComponent($input.val()))
      });
    }
  }

  let prevVal = "";

  $input.on("keydown", $evt => {
    const keyCode = $evt.originalEvent.keyCode;
    if (keyCode === 38 || keyCode === 40) {
      const $links = $content.find(`[${TEXT_ATTR}]`);
      const $active = $links.filter(".active");
      const off = keyCode === 40 ? 1 : -1;

      const currIndex = $active.length ? $links.index($active) : -1;
      const index = Math.min(currIndex + off, $links.length - 1);

      $active.removeClass("active");

      if (currIndex < 0) {
        prevVal = $input.val();
      }

      if (index >= 0) {
        const $item = $links.eq(index);
        $item.addClass("active");
        $input.val($item.attr(TEXT_ATTR));
      } else {
        $input.val(prevVal);
      }

      $evt.stopImmediatePropagation();
      $evt.preventDefault();
    } else if (keyCode === 13) {
      const el = $content.find(`[${TEXT_ATTR}].active`).get(0);
      if (el) {
        $evt.stopImmediatePropagation();
        $evt.preventDefault();
        el.click();
      }
    } else if (keyCode === 27) {
      $input.val("");
      updateContent();
    }
  });

  $input.on("focus blur keyup", VOID.utils.throttle($evt => {
    const keyCode = $evt?.originalEvent?.keyCode;
    if (keyCode === 38 || keyCode === 40 || keyCode === 13) return;
    updateContent();
  }, 100));

  $submit.on("click", function ($evt) {
    $evt.preventDefault();
    const form = $input.get(0).form;
    if (form) form.submit();
  });

  $container.on("mousedown", "a", function () {
    documentUnloading = true;
  });
}
